.casino-tabs {
  --active-line-height: 0;
  --active-line-color: transparent;
  --active-title-color: var(--T1);
  --title-font-size: 0.75rem;
  background: transparent;

  :global {
    .adm-tabs-header {
      border-bottom: none;
    }

    .adm-tabs-tab-wrapper {
      padding: 0;
    }

    .adm-tabs-tab {
      padding: 0;
    }

    .adm-tabs-tab-list{
      gap: .25rem;
    }

    .adm-tabs-header-mask{
      background: transparent;
    }
  }
}

.category-header {
  background: linear-gradient(180deg, var(--card) 0%, #232626 100%);
}

.category {
  :global {
    .grid-area {
      --gap-horizontal: 0.5rem;
      --gap-vertical: 1.25rem;
    }

    .grid-not-full {
      display: inline-grid;
    }

    .action-bar {
      .category-btn {
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: var(--button-s);
      }
      .category-btn > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 0.75rem;
      }
    }

    .swiper-slide {
      width: auto;
    }

    .bg-button-big {
      background: linear-gradient(180deg, var(--1) 0%, #cc5400 100%);
    }

    .bg-mix-lottery-sort {
      &-1 {
        background: linear-gradient(
            180deg,
            rgba(93, 205, 255, 0) 0%,
            rgba(54, 182, 239, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-2 {
        background: linear-gradient(
            180deg,
            rgba(135, 94, 248, 0) 0%,
            rgba(135, 94, 248, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-3 {
        background: linear-gradient(
            180deg,
            rgba(248, 111, 81, 0) 0%,
            rgba(249, 97, 64, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-4 {
        background: linear-gradient(
            180deg,
            rgba(205, 116, 61, 0) 0%,
            rgba(205, 116, 61, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-5 {
        background: linear-gradient(
            180deg,
            rgba(51, 195, 65, 0) 0%,
            rgba(36, 171, 49, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-6 {
        background: linear-gradient(
            180deg,
            rgba(248, 111, 81, 0) 0%,
            rgba(249, 97, 64, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-7 {
        background: linear-gradient(
            180deg,
            rgba(79, 110, 232, 0) 0%,
            rgba(47, 79, 207, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-8 {
        background: linear-gradient(
            180deg,
            rgba(148, 58, 200, 0) 0%,
            rgba(139, 42, 195, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      &-9 {
        background: linear-gradient(
            180deg,
            rgba(26, 162, 137, 0) 0%,
            rgba(36, 168, 144, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      // quick 1.5min
      &-10 {
        background: linear-gradient(
            180deg,
            rgba(251, 180, 93, 0) 0%,
            rgba(240, 164, 70, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      // quick 3min
      &-11 {
        background: linear-gradient(
            180deg,
            rgba(253, 165, 100, 0) 0%,
            rgba(243, 143, 68, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      // quick 5min
      &-12 {
        background: linear-gradient(
            180deg,
            rgba(247, 119, 77, 0) 0%,
            rgba(247, 112, 67, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }

      // bhutan lottery
      &-13 {
        background: linear-gradient(
            180deg,
            rgba(26, 59, 155, 0) 0%,
            rgba(26, 59, 155, 0.6) 100%
          ),
          linear-gradient(0deg, #323738, #323738);
      }
    }
  }
}

.circle-blur {
  background: #fff;
  opacity: 0.7;
  filter: blur(21.428569793701172px);
  border-radius: 50%;
}

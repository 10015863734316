$homeside-top-color: #00B512;

.homeside{
  right: .5rem;
}

@media (min-width: 500px) {
  .homeside{
    right: calc(50vw - 250px + .5rem);
  }
}

.homeside-top{
  background: $homeside-top-color;
  box-shadow: 0px .25rem .75rem 0px rgba(0, 0, 0, 0.25);
  transition: height 0.1s, opacity 0.25s;
}

.arrow-round{
  color: $homeside-top-color;
}